import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocalJsonForm } from "gatsby-tinacms-json"

import SEO from "../components/seo"
import { PhoneCarousel } from "../blocks/PhoneCarousel"
import { PlainSelector } from "../blocks/PlainSelector"
import { SolidBoi } from "../blocks/SolidBoi"
import { PageForm, Page } from "../templates/page"

const SecurityPage = ({ data }) => {
  const [page]: any = useLocalJsonForm(data.dataJson, SecurityPageForm)

  return (
    <Page
      title={page?.title || ""}
      description={page?.description || ""}
      bottomCta={false}
      useFooterDl
    >
      <section
        className="uk-section uk-section-muted uk-overflow-hidden"
        data-uk-scrollspy="target: h1, h2, h3, h4, p, .uk-card, .animate; cls: uk-animation-slide-bottom-small; delay: 100"
      >
        <div className="uk-container uk-position-relative">
          <div
            className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center"
            data-uk-grid="masonry: true"
          >
            <div>
              <div className="uk-flex uk-flex-center uk-position-relative animate">
                <img
                  className="uk-width-1-2 uk-width-1-1@s uk-position-absolute"
                  src="/bg.png"
                  alt=""
                  data-uk-parallax="y: -200; scale: 2; easing: 0"
                />
                <div className="uk-width-1-2 uk-width-2-3@s uk-position-relative">
                  <img src="/shield.png" alt="" />
                </div>
              </div>
            </div>

            <div className="uk-flex-first@m">
              <div className="uk-card uk-card-default uk-card-body">
                <h4 className="primary">
                  We use bank-level 256-bit encryption to transfer and store
                  information
                </h4>

                <p>
                  Sensitive information, like account credentials, are always
                  transferred and stored with the same level of encryption used
                  by top tier banks.
                </p>
              </div>
            </div>

            <div>
              <div className="uk-card uk-card-default uk-card-body">
                <h4 className="primary">
                  We use a different encryption key per user
                </h4>

                <p>
                  This additional level of security makes it so that one
                  comprised account has minimal impact.
                </p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body">
                <h4 className="primary">
                  We never store sensitive information on the app
                </h4>

                <p>
                  We store account credentials only on secure data systems
                  protected by firewalls and never on the app.
                </p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body">
                <h4 className="primary">
                  We store data on servers protected by physical security
                </h4>

                <p>
                  We keep all data on Google Cloud and AWS, which is used by the
                  U.S. Department of Defense, Financial Industry Regulatory
                  Authority (FINRA) and Capital One.
                </p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body">
                <h4 className="primary">We will never sell your data</h4>

                <p>
                  No one has access to our data other than us, and we will never
                  sell your personal information.{" "}
                  <a
                    href="http://help.maxrewards.co/en/articles/3705925/"
                    target="_blank"
                  >
                    Learn how we make money.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

const SecurityPageForm = {
  label: "Security Page",
  fields: [
    {
      name: "rawJson.title",
      label: "Title",
      component: "text",
    },
    {
      name: "rawJson.description",
      label: "Subtitle",
      component: "textarea",
    },
    {
      name: "rawJson.comp1Tit",
      label: "Security 1 Title",
      component: "text",
    },
    {
      name: "rawJson.comp1Desc",
      label: "Security 1 Description",
      component: "textarea",
    },
    {
      name: "rawJson.comp2Tit",
      label: "Security 2 Title",
      component: "text",
    },
    {
      name: "rawJson.comp2Desc",
      label: "Security 2 Description",
      component: "textarea",
    },
    {
      name: "rawJson.comp3Tit",
      label: "Security 3 Title",
      component: "text",
    },
    {
      name: "rawJson.comp3Desc",
      label: "Security 3 Description",
      component: "textarea",
    },
    {
      name: "rawJson.comp4Tit",
      label: "Security 4 Title",
      component: "text",
    },
    {
      name: "rawJson.comp4Desc",
      label: "Security 4 Description",
      component: "textarea",
    },
    {
      name: "rawJson.comp5Tit",
      label: "Security 5 Title",
      component: "text",
    },
    {
      name: "rawJson.comp5Desc",
      label: "Security 5 Description",
      component: "textarea",
    },
  ],
}

export const query = graphql`
  query securityQuery {
    dataJson(fileRelativePath: { eq: "/src/data/security.json" }) {
      title
      description

      # TinaCMS fields
      rawJson
      fileRelativePath
    }
  }
`

export default SecurityPage
